<template>
  <div class="notice">
    <van-cell>
      <template #title>
        <div class="blue-box"></div>
        <span class="custom-title">{{title}}</span>
      </template>
    </van-cell>
    <van-tabs v-model="activeName" class="tab" title-active-color="#027eff" color="#027eff" @click="tabChange">
      <van-tab name = '0' title="调查问卷" v-if="isAnonymous !== '0' || status !== '2'"></van-tab>
      <van-tab name = '1' title="数据统计"></van-tab>
    </van-tabs>
    <div v-if="activeName == '0'">
      <div v-if="participate == 'false' || participate == null" class="write">
        <div v-for="(item, index) in dataList" :key="index">
          <van-cell class="cell" v-if="item.type == '1'">
            <div class="title">{{index + 1}}.{{item.content}} ({{item.typeStr}})</div>
            <div v-for="(val, key) in item.optionsArr" :key="key" v-model="item.optionsArr[item.id]">
              <div class="list">
                <input type="radio" :id="item.id + val + key" :name="item.id" :value="val" @click="radioChange(item, val, $event)"/>
                <label :for="item.id + val + key">{{val}}</label>
              </div>
            </div>
          </van-cell>
          <van-cell v-if="item.type == '2'">
            <div class="title">{{index + 1}}.{{item.content}} ({{item.typeStr}})</div>
            <van-checkbox-group v-model="checks">
              <van-checkbox shape="square" label-disabled v-for="(items, index) in item.optionsArr" :key="index" :name="item.id + items" @click="checkUpdate(item.id, item.type, item.qnId, items)">
                {{items}}
              </van-checkbox>
            </van-checkbox-group>
          </van-cell>
          <div class="checkbox" v-if="item.type == '0'">
            <div class="title">{{index + 1}}.{{item.content}} ({{item.typeStr}})</div>
            <van-cell class="field">
              <van-field
                  v-model="item.answer"
                  rows="4"
                  autosize
                  type="textarea"
                  placeholder="请输入内容"
                  @blur="textChange(item)"
              />
            </van-cell>
          </div>
        </div>
        <van-button type="info" size="large" :loading="loading" round @click="submit()" style="margin-top: 5%;" v-if="status != 2">提交</van-button>
      </div>
      <div v-if="participate == 'true'" class="success">
        <div v-for="(item, index) in infoList" :key="index">
          <van-cell class="cell" v-if="item.type == '1'">
            <div class="title">{{index + 1}}.{{item.content}} ({{item.typeStr}})</div>
            <ul v-for="(val, key) in item.optionsArr" :key="key">
              <li :class="{'checked': item.answer.toString().indexOf(val)!=-1}"> {{val}} <img :src="check" v-if="item.answer.toString().indexOf(val)!=-1"/></li>
            </ul>
            <!--            <van-radio-group v-model="item.answer.toString()">-->
            <!--                <van-radio :name="val" v-for="(val, key) in item.optionsArr" :key="key" :class="{'checked': item.answer.toString().indexOf(val)!=-1}">-->
            <!--                  {{val}}-->
            <!--                  <template #icon="props">-->
            <!--                    <img class="img-icon" :src="props.checked ? check : ' '" />-->
            <!--                  </template>-->
            <!--                </van-radio>-->
            <!--            </van-radio-group>-->
          </van-cell>
          <van-cell v-if="item.type == '2'">
            <div class="title">{{index + 1}}.{{item.content}} ({{item.typeStr}})</div>
            <ul v-for="(val, key) in item.optionsArr" :key="key">
              <li :class="{'checked': item.answer.toString().indexOf(val)!=-1}"> {{val}} <img :src="check" v-if="item.answer.toString().indexOf(val)!=-1"/></li>
            </ul>
            <!--            <van-checkbox-group v-model="item.answer">-->
            <!--                <van-cell v-for="(val, key) in item.optionsArr" :key="val" :title="val">-->
            <!--                  <van-checkbox :name="val" ">-->
            <!--                    <template #right-icon="props">-->
            <!--                      <img class="img-icon" :src="props.checked ? check : ''" />-->
            <!--                    </template>-->
            <!--                  </van-checkbox>-->
            <!--                </van-cell>-->
            <!--            </van-checkbox-group>-->
          </van-cell>
          <div class="checkbox" v-if="item.type == '0'">
            <div class="title">{{index + 1}}.{{item.content}} ({{item.typeStr}})</div>
            <van-cell class="field">
              <van-field
                  v-model="item.answer[0]"
                  rows="4"
                  autosize
                  readonly
                  type="textarea"
                  placeholder="请输入内容"
              />
            </van-cell>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeName == '1'">
      <question-total ref="total" :useTotal="useTotal"></question-total>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {formatterDate} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import { mapMutations } from 'vuex'
import questionTotal from "./questionTotal";
export default {
  components :{
    topBar,
    questionTotal
  },
  data() {
    return {
      checked: true,
      loading: false,
      isChecked: true,
      id: 0,
      questionResults: [],
      answer: '',
      participate: '',
      isAnonymous: '',
      surveyObject: '',
      activeName: '0',
      status: '',
      useTotal: '',
      title: '',
      result: [],
      dataList: [],
      infoList: [],
      checks: [],
      check: require('@/assets/img/check.png')
    };
  },
  mounted() {
    if (this.isAnonymous == '0' && this.status == '2') {
      this.$nextTick(function () {
        setTimeout(() => {
          this.$refs.total.initChart()
        }, 500)
      })
    }
  },
  created() {
    let id = this.$route.query.id
    this.status = this.$route.query.status
    this.participate = this.$route.query.participate
    this.isAnonymous = this.$route.query.isAnonymous
    this.useTotal = this.$route.query.useTotal
    this.title = this.$route.query.shortName
    console.log(this.participate)
    if (this.participate == 'true' ) {
      this.getQuestionInfo(id)
    } else if(this.participate == 'false' || this.participate == null) {
      this.getInfo(id)
    }
  },
  // mounted() {
  //     this.$refs.total.initChart()
  // },
  methods: {
    tabChange(value) {
      if(value == '1') {
        this.$nextTick(function () {
          setTimeout(() => {
            this.$refs.total.initChart()
            // this.$refs.total.initColumn()
          }, 500)
        })
      }
    },
    //单项选择
    radioChange(item, val, e) {
      let qId = item.id
      let type = item.type
      let qnId = item.qnId
      let qIndex = null
      this.questionResults.forEach((item, index) => {
        if (item.qId == qId) {
          qIndex = index
        }
      })
      if (!qIndex && qIndex != 0) {
        this.questionResults.push({qId: qId, answer: val, qType: Number(type), qnId: qnId})
      } else {
        this.questionResults[qIndex].answer = val
      }
    },
    //多项选择
    checkUpdate(id, type, qnId, items){
      console.log(this.checks)
      let index = this.checks.findIndex(item => {
        return item == id + items
      })
      if (index == -1) {
        this.questionResults = this.questionResults.filter(item => {
          return item.answer != items
        })
      } else {
        this.questionResults.push({qId: id, answer: items, qType: Number(type), qnId: qnId})
      }
      console.log(this.questionResults)
    },
    textChange(item){
      let qId = item.id
      let type = item.type
      let qnId = item.qnId
      let answer = item.answer
      let qIndex = null
      this.questionResults.forEach((val, index) => {
        if (val.qId == qId) {
          qIndex = index
        }
      })
      if (!qIndex){
        this.questionResults.push({qId: qId, answer: answer, qType: Number(type), qnId: qnId})
      } else {
        this.questionResults[qIndex].answer = answer
      }
    },
    getInfo(id) {
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/questionnaire/info'),
        method: 'post',
        params: this.$httpApp.adornParams({
          id: id
        },false)
      }).then(({data}) => {
        if (data.code == 0) {
          this.dataList = data.Questionnaire.questionEntityList
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    getQuestionInfo(id) {
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/questionnaire/answer/info'),
        method: 'post',
        params: this.$httpApp.adornParams({
          id: id,
          userId: this.$globalData.userInfo.userId
        },false)
      }).then(({data}) => {
        if (data.code == 0) {
          this.infoList = data.answerInfo

        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    submit() {
      let idLength = []
      let dataArr = []
      let radioLength = []
      let shortLength = []
      let radio = 0
      let multiple = 0
      let text = 0
      this.dataList.map(item => {
        if (item.type === '0') {
          text = 1
        }
        if (item.type === '1') {
          radio = 1
        }
        if (item.type === '2') {
          multiple = 1
        }
      })
      for (var j=0;j<this.questionResults.length;j++) {
        if (this.questionResults[j].qType === 2 ) {
          idLength.push(this.questionResults[j].qId)
        }
        if (this.questionResults[j].qType === 1) {
          radioLength.push(this.questionResults[j].qId)
        }
        if (this.questionResults[j].qType === 0) {
          shortLength.push(this.questionResults[j].qId)
        }
      }
      let arr = idLength.sort()
      for (var i = 0; i < arr.length; i++) {
        if (arr.indexOf(arr[i]) === arr.lastIndexOf(arr[i])) {
          dataArr.push(arr[i])
        }
      }
      if (idLength.length === 0 && multiple === 1) {
        this.$toast.fail ({
          message: '请填写',
          duration: 1500,
        })
      } else if (radioLength.length === 0 && radio === 1) {
        this.$toast.fail ({
          message: '请填写',
          duration: 1500,
        })
      } else if (shortLength.length === 0 && text === 1) {
        this.$toast.fail({
          message: '请填写',
          duration: 1500,
        })
      } else if(dataArr.length !== 0){
        this.$toast.fail ({
          message: '多选项不能只填写一个选项',
          duration: 1500,
        })
      } else {
        this.$httpApp({
          url: this.$httpApp.adornUrl('/wxapp/questionresult/save'),
          method: 'post',
          data: this.$httpApp.adornData({
            'street': this.isAnonymous == '0'? ' ' : this.$globalData.userInfo.street,
            'community':  this.isAnonymous == '0'? ' ' : this.$orgId,
            'createUserId':  this.isAnonymous == '0'? ' ' : this.$globalData.userInfo.userId,
            'questionResults': this.questionResults
          })
        }).then(({data}) => {
          if (data.code == 0) {
            this.$toast.success ({
              message: data.msg,
              duration: 3000,
              onClose: () =>{
                this.$router.go(-1)
              }
            })
          } else {
            this.$toast.fail(data.msg);
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.checked {
  color: #387FF5;
  background-color: #F4F8FF;
}
.notice {
  background-color: #fff;
}
.write {
  height: 85vh;
  overflow-y: scroll;
  .notice {
    background-color: #fff;
  }
  .custom-title {
    font-size: 32px;
  }
  .cell {
    padding-bottom: 2px;
  }
  .blue-box {
    margin-bottom: 40px;
  }
  .van-cell__value--alone {
    margin-left: 25px;
  }
  .van-cell::after {
    border-bottom: none;
  }
  .checkbox {
    .title {
      font-size: 34px;
      font-weight: 600;
      margin-left: 50px;
      margin-top: 50px;
    }
  }
  .img-icon {
    height: 30px;
  }
  .title {
    font-size: 34px;
    font-weight: 600;
    //margin-left: 50px;
    margin-bottom: 20px;
  }
  .van-radio-group {
    margin-left: 50px;
  }
  .van-checkbox {
    margin-bottom: 30px;
  }
  ::v-deep .van-checkbox__label--disabled {
    color: #000;
  }
  ::v-deep .van-checkbox__icon--disabled .van-icon {
    background-color: #fff;
  }
  input[type="radio"] + label::before {
    content: ""; /*不换行空格*/
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    width: 30px;
    height: 30px;
    margin-right: 15px;
    margin-bottom: 1px;
    border-radius: 50%;
    border: 1px solid #387EF6;
    text-indent: 3px;
    line-height: 1;
  }
  input[type="radio"] + label {
    font-size: 30px;
  }
  input[type="radio"]:checked + label::before {
    background-color: #387EF6;
    background-clip: content-box;
    padding: 3px;
  }
  input[type="radio"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
  .field {
    .van-cell__value--alone {
      margin-left: 0;
    }
    .van-field {
      //padding: 0;
      padding: 0 0 0 30px;
      border: 1px solid #eee;
    }
  }
}

.success {
  height: 85vh;
  overflow-y: scroll;
  ::v-deep .van-field__control:read-only {
    color: #387FF5;
  }
  .cell {
    padding-bottom: 2px;
  }
  ul {
    padding-left: 20px;
    li {
      position: relative;
      height: 65px;
      line-height: 65px;
      margin-bottom: 20px;
      img {
        position: absolute;
        right: 15px;
        top: 15px;
        width: 32px;
        height: 28px;
      }
    }
  }
  .blue-box {
    margin-bottom: 40px;
  }
  .van-cell__value--alone {
    margin-left: 25px;
  }
  .van-radio {
    height: 65px;
    margin-bottom: 10px;
  }
  .van-cell::after {
    border-bottom: none;
  }
  .checkbox {
    .title {
      font-size: 34px;
      font-weight: 600;
      margin-left: 50px;
      margin-top: 50px;
    }
  }
  .img-icon {
    position: absolute;
    right: 0;
    height: 30px;
  }
  .title {
    font-size: 34px;
    font-weight: 600;
    //margin-left: 50px;
    margin-bottom: 20px;
  }
  [class*=van-hairline]::after {
    border: none;
  }
  .van-checkbox {
    margin-bottom: 30px;
    display: none;
  }
  .van-field__control {
    color: #2271F3 !important;
  }
  .van-cell > .van-field__control {
    color: #2271F3 !important;
  }
  .field {

    .van-field {
      color: #2271F3;
      background-color: #F4F8FF;
      //padding: 0;
      padding: 10px 0 0 30px;
      //border: 1px solid #eee;

    }
  }
}
</style>
